<template>
  <!-- pin START -->
  <div class="account-pin">
    <p v-html="$t('account.pin.description')"></p>

    <!-- form:pin START -->
    <el-form
      status-icon
      :rules="rules"
      ref="pinForm"
      :model="pinForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('pinForm')"
    >
      <!-- form:pin:pin START -->
      <el-form-item prop="pin">
        <el-input
          class="pin"
          type="number"
          inputmode="numeric"
          :placeholder="`${$t('login.messagepin')} *`"
          @keyup.enter.native="submitForm('pinForm')"
          v-model="pinForm.pin"
        ></el-input>
      </el-form-item>
      <!-- form:pin:pin END -->

      <!-- form:pin:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="submitForm('pinForm')"
        >{{ $t('login.save') }}</el-button>
      </el-form-item>
      <!-- form:pin:submit END -->
    </el-form>
    <!-- form:pin END -->

    <!-- form:pin:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:pin:loading END -->
  </div>
  <!-- pin END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'pin',
  mixins: [titleMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.pin.headline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      pinForm: {
        pin: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        pin: [
          {
            required: true,
            pattern: /^[^-\s][a-zA-Z0-9_\s-]+$/,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            pattern: /^\d{4}$/,
            message: 'Bitte geben Sie 4-stelliger Code der Zahlen 0 bis 9 ein.',
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'pinForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Data
             */
            formData.append('pinCode', this.pinForm.pin);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Update user data
            this.$http.post(`${process.env.VUE_APP_BASE_URL}/user/pin`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  // Close loading
                  this.loadingVisible = false;

                  // notification - error
                  this.success();
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.loadingVisible = false;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    success() {
      this.$notify({
        message: this.$t('notification.success'),
        type: 'success',
        position: 'bottom-right',
      });

      // clean up input fields
      setTimeout(() => {
        this.resetForm('pinForm');
      }, 750);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
};
</script>
