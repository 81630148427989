/**
 * Title Mixin
 *
 * This mixin provides custom title function.
 * This is intented to be used in Navigation (Simple) component.
 */

import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      pageTitleFromComponent: 'syreta',
    };
  },
  computed: {
    ...mapGetters({
      pageTitle: 'pageTitle',
    }),
  },
  methods: {
    ...mapActions([
      'addPageTitle',
      'removePageTitle',
    ]),
  },
  mounted() {
    this.addPageTitle(this.pageTitleFromComponent);
  },
  destroyed() {
    this.removePageTitle();
  },
};
